import styles from './TextInput.module.scss'

type TextInputType = {
  name: string,
  label?: string,
  value: string,
  type?: 'text'|'password'|'email'|'number',
  isReadOnly?: boolean,
  onChange: (value: string) => void,
}

export function TextInput({ name, label, value, onChange, type, isReadOnly }: TextInputType) {

  const inputLabel = label ? <label htmlFor={ name }>{ label }</label> : null

  return (
    <div className={ styles.textInput }>
      { inputLabel }
      <input type={ type ?? 'text' } id={ name } readOnly={ isReadOnly } value={ value } onChange={ ev => onChange(ev.target.value) } />
    </div>
  )
}
