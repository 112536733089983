import React from 'react'

import styles from './Button.module.scss'

type ButtonType = {
  onClick: () => void,
  isDisabled?: boolean,
  size?: 'normal'|'small',
  children: React.ReactNode,
}

// More about forwareRef with TypeScript: https://bit.ly/3Dd8FHF
export const Button = React.forwardRef<HTMLButtonElement, ButtonType>(function formButton({ onClick, isDisabled, size, children }: ButtonType, ref) {

  const classNames = `${ styles.btn } ${ size ? styles[size] : '' }`

  return (
    <button className={ classNames } onClick={ onClick } disabled={ isDisabled } ref={ref}>
      { children }
    </button>
  )
});

