import { useEffect, useState, useContext } from 'react'

import * as EmailValidator from 'email-validator';

import styles from './login.module.scss'

import { PageView } from '../../components/Analytics/PageView/PageView'
import { TextInput } from '../../components/Form/TextInput/TextInput'
import { Button } from '../../components/Form/Button/Button'
import { SessionContext, SessionContextShape } from '../../contexts/SessionContext'
import { SessionType } from '../../types/SessionType';
import { useRouter } from 'next/router';
import { DataLayerAPI } from '../../api/DataLayerAPI';
import Head from 'next/head';

export default function LoginPage() {

  const sessionContext = useContext<SessionContextShape>(SessionContext)
  const dataLayerAPI =new DataLayerAPI()

  const minOrderIdLength = 5

  const [ email, setEmail ] = useState('')
  const [ orderId, setOrderId ] = useState('')
  const [ formValid, setFormValid ] = useState(false)
  const [ formSubmitting, setFormSubmitting ] = useState(false)
  const [ loginFailedMessage, setLoginFailedMessage ] = useState('')

  const router = useRouter()

  // Check form is valid:
  useEffect(() => {
    setFormValid(orderId.length >= minOrderIdLength && EmailValidator.validate(email))
  }, [email, orderId])

  const login = async () => {
    if (sessionContext.sessionAPI) {
      setFormSubmitting(true)
      setLoginFailedMessage('')

      let session: SessionType | false = false
      try {
        session = await sessionContext?.sessionAPI?.login(email, orderId)
        if (session) {
          router.push('/account')
          dataLayerAPI.loginEvent(session)
        }
      } catch {
        setLoginFailedMessage("Please check your login details and try again.")
        setFormSubmitting(false)
      }

      if (!session) {
        setLoginFailedMessage("Please check your login details and try again.")
        setFormSubmitting(false)
      }
    }
  }

  const loginFailed = loginFailedMessage ? <p className={ styles.loginFailed }>{ loginFailedMessage }</p> : null

  return (
    <div className={ styles.login } id='maincontent'>
      <Head key={'login'}><title>Universal Orlando™ | Login</title></Head>
      <PageView
        pageName='Login'
        subCategory1='Account Management'
        subCategory2='Login'
      />
      <h1>Log in</h1>
      <form>
        { loginFailed }
        <TextInput name='email' label='Email' value={ email } onChange={ setEmail } isReadOnly={ formSubmitting } type={ 'email' } />
        <TextInput name='order_id' label='Order id' value={ orderId } onChange={ setOrderId } isReadOnly={ formSubmitting } type={ 'number' } />
        <Button onClick={ () => login() } isDisabled={ formSubmitting || !formValid }>Login</Button>
      </form>
    </div>
  )
}
